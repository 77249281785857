<template>

    <vue-draggable-resizable :resizable="true" :parent="true" :z="zIndex" :min-width="200" :min-height="200"
                             :w="432" :h="244" :grid="[16, 9]" :x="xCoord" :y="yCoord"
                             @dragstop="adjustVideoPosition" @activated="zIndex = 1001" @deactivated="zIndex = 1000"
                             style="background-color: #1a1a1a; border: 1px solid #000000">

        <b-card :id="'camGroup-card' + cameraGroupId" no-body header-tag="header" bg-variant="dark" style="margin: 0; overflow-y: hidden; border: none;">

            <!--Header-->
            <span slot="header" style="background-color: #2f353a;">
                <span class="d-flex w-100 justify-content-between">
                    <h3 style="margin: 0 2px; color: white;">{{ name }}</h3>
                    <fa-icon :icon="['fas', 'close']" @click="closePopUp" class="d-flex justify-content-end"
                             style="margin: 2px 2px 0 0; color: white; font-size: 20px; cursor: pointer"/>
                </span>
            </span>

            <!--Cameras-->
            <div style="overflow-y: hidden">
                <div style="overflow-y: auto">
                    <div v-for="camera in cameras" :key="camera.sourceToken + update" style="color: black; height: 30px">
                        <!-- Active Camera -->
                        <div class="d-flex w-100 justify-content-between" style="margin: 0">
                            <h5 style="margin: 0; color: white; padding-left: 10px;">{{ camera.friendlyName }}</h5>
                            <fa-icon v-if="isUp[camera.sourceToken]" :icon="['fas', 'eye-slash']" class="d-flex justify-content-end"
                               style="margin: 0; padding: 3px 10px 0 0; color: white; cursor: pointer" @click="closeCamera(camera.sourceToken)"/>
                            <fa-icon v-else :icon="['fas', 'eye']" class="d-flex justify-content-end"
                               style="margin: 0; padding: 3px 10px 0 0; color: white; cursor: pointer" @click="openVideoPopUp(camera)"/>
                        </div>

                    </div>
                </div>
            </div>

        </b-card>

    </vue-draggable-resizable>

</template>

<script>
    import Vue from 'vue';
    import mapViewStore from '../../store/mapViewStore';
    import VueDraggableResizable from 'vue-draggable-resizable';
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
    import PubSub from "pubsub-js";

    Vue.component('vue-draggable-resizable', VueDraggableResizable);

    export default {
        name: "CameraGroupPopUp",

        props: {
            name: {type: String},
            cameraGroupId: {type: Number},
            cameraGroups: {type: Array},
            cameras: {type: Array},
            device: {type: Object}
        },

        data() {
            return {
                xCoord: 0,
                yCoord: 0,
                zIndex: 1000,
                isUp: {},
                pubSub: null,
                update: 0

            }
        },

        created() {
            // first, make sure the matrix is populated before determining where to place the pop-up
            // add the piece then repopulate the matrix
            mapViewStore.dispatch('setMatrix').then(() => {
                this.findPlacement();
            }).then(() => {
                mapViewStore.dispatch('addPopUpPosition', {
                    type: 'cameraGroup',
                    id: this.cameraGroupId,
                    coordinates: {
                        leftMost: this.xCoord,
                        topMost: this.yCoord
                    },
                    size: {
                        width: 400,
                        height: 297
                    }
                });
            }).then(() => {
                mapViewStore.dispatch('setMatrix');
            });
            this.cameras.forEach(camera => {
                this.isUp[camera.sourceToken] = document.getElementById("video-container-" + this.device.getDeviceId() + camera.sourceToken + undefined)
            })
            this.pubSub = PubSub.subscribe('isUp' + this.device.getDeviceId(), (msg, data) => {
                this.update++
                this.isUp[data.sourceToken] = data.isUp;
            })
        },

        mounted() {
            let camerasLength = this.cameras.length * 30 + 33;
            let calculatedLength = this.cameras.length * 30 + 33 >= 200 ? camerasLength : 200;
            document.getElementById('camGroup-card' + this.cameraGroupId).style.height = JSON.stringify(calculatedLength) + 'px';
        },

        methods: {
            closeCamera(sourceToken) {
                PubSub.publish('closeStream' + this.device.getDeviceId(), sourceToken)
            },
            adjustVideoPosition(x, y) {
                // repopulate matrix with adjusted pop up position
                mapViewStore.dispatch('resizeMovePopUp', {
                    type: 'cameraGroup',
                    id: this.cameraGroupId,
                    coordinates: {
                        leftMost: x,
                        topMost: y
                    },
                    size: {
                        width: 400,
                        height: 297
                    }
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            closePopUp() {
                // replaces camera groups array with new one that has deleted camera group sliced out
                let newPopUps = [...this.cameraGroups];
                const targetIndex = this.cameraGroups.findIndex(cameraGroup => {
                    return cameraGroup.id === this.cameraGroupId;
                });
                newPopUps.splice(targetIndex, 1);
                // let the MapView parent component know that the pop-up was removed
                this.$emit('closeCameraGroupPopUp', newPopUps);
                // then repopulate the matrix with accounting for the pop-up missing
                mapViewStore.dispatch('removePopUpPosition', {
                    type: 'cameraGroup',
                    id: this.cameraGroupId
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            findPlacement() {
                // starting at the top left, then traversing width then length, find the first placement
                // where all four corners of the placement in the matrix are populated by a false boolean
                // which represents there is no pop up populating the space
                for (let j = 0; j < mapViewStore.state.windowHeight - 297; j += 9) {
                    for (let i = 0; i < mapViewStore.state.windowWidth - 400; i += 16) {
                        const isGoodPlacement = mapViewStore.state.matrix[i][j] === false && mapViewStore.state.matrix[i + 399][j] === false
                            && mapViewStore.state.matrix[i][j + 296] === false && mapViewStore.state.matrix[i + 399][j + 296] === false;
                        if (isGoodPlacement === true) {
                            this.xCoord = i;
                            this.yCoord = j;
                            return;
                        }
                    }
                }
                // if there are no open placements on the screen, place the pop-up at the top left
                this.xCoord = 0;
                this.yCoord = 0;
            },
            openVideoPopUp(camera) {
                this.$set(camera, 'openBool', true);
                this.$emit('openVideoPopUp', camera);
            }
        },

        beforeDestroy() {
            PubSub.unsubscribe(this.pubSub)
        },

        computed: {
            minHeight() {
                return this.cameras.length * 30 + 33;
            },
            minWidth() {
                return this.name.length * 15 + 25;
            }
        }
    }
</script>

<style scoped>
header {
    padding: 10px;
}
</style>
