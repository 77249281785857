<template>

    <vue-draggable-resizable :id="'draggable'+ popUpId" :parent="true" :grid="[16, 9]" :z="zIndex" v-if="device"
                             :lock-aspect-ratio="true" :w="432" :h="244" :minHeight="144" :minWidth="256"
                             @resizing="adjustVideoHeight" @dragstop="adjustVideoPosition" @resizestop="adjustVideoSize"
                             @activated="zIndex = 1001" @deactivated="zIndex = 1000"
                             :x="xCoord" :y="yCoord" :handles="['tl', 'tr', 'bl', 'br']"
                             style="background-color: black;">

            <!--Video Feed-->
            <div :id="'grid-div' + popUpId" style="width: 100%; height: 100%">
                <div :id="'video-container-' + device.getDeviceId() + sourceToken + dataChannel" class="flex-fill d-flex noHighlight" style="position: relative; height: 100%; width: 100%">
                    <stream :device="device" :sourceToken="sourceToken" :dataChannel="dataChannel" style="width: 100%; height: 100%" class="d-flex" @madePlayer="updatePlayer"/>
                    <PlayerControls :device="device"
                                    :isStreamOptions="aside.bool && aside.name === name"
                                    :sourceToken="sourceToken"
                                    :dataChannel="dataChannel"
                                    style="position: absolute" class="flex-fill h-100 w-100"
                                    containerIDPrefix="video-container-"
                                    @openStreamOptions="toggleAside" @closeStreamOptions="toggleAside"
                                    @close="closePopUp"/>

                </div>
            </div>

    </vue-draggable-resizable>

</template>

<script>
    import Vue from 'vue';
    import VueDraggableResizable from 'vue-draggable-resizable';
    import mapViewStore from '../../store/mapViewStore';
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
    import { Aside as AppAside } from '../template_files/navbarJazz';
    import AsideVideoToggler from "./AsideVideoToggler";
    import PlayerControls from "@/views/video_wall/stream_components/PlayerControls.vue";
    import stream from "@/views/video_wall/stream_components/stream.vue";
    import PubSub from "pubsub-js";

    Vue.component('vue-draggable-resizable', VueDraggableResizable);

    export default {
        name: "VideoPopUp",
        props: {
            device: {
                type: Object,
                default: null
            },
            sourceToken: {
                type: String,
                default: '0',
            },
            dataChannel: {
                type: String,
                default: undefined
            },
            name: {type: String},
            aside: {type: Object},
            popUpId: {type: Number},
            popUps: {type:  Array}
        },
        components: {
            AppAside,
            AsideVideoToggler,
            stream,
            PlayerControls
        },
        data() {
            return {
                xCoord: 0,
                yCoord: 0,
                zIndex: 1000,
                player: {},
                pubSub: null
            }
        },
        created() {
            PubSub.publish('isUp' + this.device.getDeviceId(), {sourceToken: this.sourceToken, isUp: true})
            this.pubSub = PubSub.subscribe('closeStream' + this.device.getDeviceId(), (msg, data) => {
                if (this.sourceToken === data) {
                    this.closePopUp()
                }
            })
            // first, make sure the matrix is populated before determining where to place the pop-up
            // add the piece then repopulate the matrix
            mapViewStore.dispatch('setMatrix').then(() => {
                this.findPlacement();
            }).then(() => {
                mapViewStore.dispatch('addPopUpPosition', {
                    type: 'video',
                    id: this.popUpId,
                    coordinates: {
                        leftMost: this.xCoord,
                        topMost: this.yCoord
                    },
                    size: {
                        width: 432,
                        height: 244
                    }
                }).then(() => {
                    this.adjustVideoHeight(this.xCoord, this.yCoord, 432, 243);
                    mapViewStore.dispatch('setMatrix');
                });
            })
        },
        beforeDestroy() {
            PubSub.publish('isUp' + this.device.getDeviceId(), {sourceToken: this.sourceToken, isUp: false})
            PubSub.unsubscribe(this.pubSub)
            if (this.aside.bool && this.aside.name === this.name) {
                this.$emit('toggleAside', {
                    bool: false,
                    device: this.device,
                    sourceToken: this.sourceToken,
                    dataChannel: this.dataChannel,
                    name: this.name
                });
            }
        },
        methods: {
            updatePlayer(player) {
                this.player = player;
            },
            adjustVideoHeight(x, y, width, height) {
                let numWidth = Math.floor(width)
                let numHeight = Math.floor((numWidth / 16) * 9)
                const newWidth = JSON.stringify(numWidth - 1) + 'px';
                const newHeight = JSON.stringify(numHeight - 1) + 'px';
                document.getElementById('grid-div' + this.popUpId).style.width = newWidth;
                document.getElementById('grid-div' + this.popUpId).style.height = newHeight;
            },
            adjustVideoPosition(x, y) {
                // repopulate matrix with adjusted pop up position

                // get the popUp with a getter in order to find its appropriate width and height
                let popUp = mapViewStore.getters.getPopUp('video', this.popUpId);

                mapViewStore.dispatch('resizeMovePopUp', {
                    type: 'video',
                    id: this.popUpId,
                    coordinates: {
                        leftMost: x,
                        topMost: y
                    },
                    size: {
                        width: Math.floor(popUp.size.width),
                        height: Math.floor(popUp.size.height)
                    }
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            adjustVideoSize(x, y, width, height) {
                // repopulate matrix with adjusted pop up size
                mapViewStore.dispatch('resizeMovePopUp', {
                    type: 'video',
                    id: this.popUpId,
                    coordinates: {
                        leftMost: x,
                        topMost: y
                    },
                    size: {
                        width: Math.floor(width),
                        height: Math.floor(height)
                    }
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            closePopUp() {
                // replaces video pop-ups array with new one that has the video pop up sliced out
                let newPopUps = [...this.popUps];
                const targetIndex = this.popUps.findIndex(video => {
                    return video.id === this.popUpId;
                });

                newPopUps.splice(targetIndex, 1);
                // let the MapView parent component know that the pop-up was removed
                this.$emit('closeVideoPopUp', {
                    videoPopUps: newPopUps,
                    id: this.popUpId,
                    name: this.name,
                    camera: this.device
                });
                // then repopulate the matrix with accounting for the pop-up missing
                mapViewStore.dispatch('removePopUpPosition', {
                    type: 'video',
                    id: this.popUpId
                }).then(() => {
                    mapViewStore.dispatch('setMatrix');
                });
            },
            findPlacement() {
                // starting at the top left, then traversing width then length, find the first placement
                // where all four corners of the placement in the matrix are populated by a false boolean
                // which represents there is no pop up populating the space
                for (let j = 0; j < mapViewStore.state.windowHeight - 243; j += 9) {
                    for (let i = 0; i < mapViewStore.state.windowWidth - 384; i += 16) {
                        const isGoodPlacement = mapViewStore.state.matrix[i][j] === false && mapViewStore.state.matrix[i + 383][j] === false
                            && mapViewStore.state.matrix[i][j + 242] === false && mapViewStore.state.matrix[i + 383][j + 242] === false;
                        if (isGoodPlacement === true) {
                            this.xCoord = i;
                            this.yCoord = j;
                            return;
                        }
                    }
                }
                // if there are no open placements on the screen, place the pop-up at the top left
                this.xCoord = 0;
                this.yCoord = 0;
            },
            async toggleAside() {
                let videoContainer = document.getElementById('video-container-' + this.playerId);
                if (this.aside.bool && this.aside.name === this.name) {
                    await document.getElementById('grid-div' + this.popUpId).appendChild(videoContainer);
                    this.$emit('toggleAside', {
                        bool: false,
                        device: null,
                        sourceToken: null,
                        dataChannel: null,
                        name: null
                    });
                } else {
                    this.$emit('toggleAside', {
                        bool: true,
                        device: this.device,
                        sourceToken: this.sourceToken,
                        dataChannel: this.dataChannel,
                        name: this.name
                    });
                    await document.getElementById('streamSettings').appendChild(videoContainer);
                }
            }
        },
        computed: {
            playerId() {
                return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
            }
        },
    }
</script>

<style scoped>

</style>